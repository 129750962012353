export default{
    methods:{
        absoluteUrl(path = ""){
            return this.$store.state.config.domain+path
        },
        isImageFile(ext){
            return  ['png','jpeg','svg','jpg','webp'].includes(ext ? ext.toLowerCase() : null)
        },
        calculateDeliveryTime(time){
            return time > 24
               ?  `${time/24} Days`
               : `${time} Hrs`
        },
        preText(content) {
            if(!content) return;
            const words = content.replace(/\n/g, ' <br>').split(' ');
            const formattedWords = [];
            words.forEach(word => {
              if (word.startsWith('http://') || word.startsWith('https://')  || word.startsWith('www.')) {
                formattedWords.push(`<a href="${word}" class="text-success" target="_blank"> ${word} </a>`);
              } else {
                formattedWords.push(word)
              }
            });
            return formattedWords.join(' ')
        },
        statusBadge(status){
          if(!status) return;
          let code = { active : '#0ec569', pending : '#0ec569', completed : '#000000', refunded  : '#EA5455', canceled  : '#EA5455', past_due  : '#EA5455',
                      draft  : '#EA5455', progress : '#41d051', trialing : '#41d051', revision: '#446ee7', paid : '#000000' }
          return `<span style="background:${code[status.toLowerCase()]}24; color:${code[status]}; padding:0px 5px 2px 5px;line-height:1; border-radius:4px;">${status}</span>`
        },
        scrollToView(elID){
            if(!elID){return}
            const yOffset = -200;
            const element = document.getElementById(elID);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        },
        alertSuccess(text){
            this.$store.dispatch("alertSuccess", text)
        },
        alertError(text){
            this.$store.dispatch("alertError", text)
        },
        confirmDelete(option){
          return new Promise((resolve, reject) => {
            Swal.fire({
              title: option.title || "Are you sure?",
              text: option.text,
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: "#f1556c",
              cancelButtonColor: "#111",
              confirmButtonText: "Delete it!",
            }).then((result) => {
              resolve(result)
            }).catch((error) => { reject(error) });
          })
        },
        confirmAction(option){
          return new Promise((resolve, reject) => {
            Swal.fire({
              title: option.title || "Confirm action!",
              text: option.text,
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: "#0ec569",
              cancelButtonColor: "#111",
              confirmButtonText: "Yes, Proceed!",
            }).then((result) => {
              resolve(result)
            }).catch((error) => { reject(error) });
          })
        }
    },
}
