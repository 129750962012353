export default [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: '/main',
    redirect: '/',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import('@views/Dashboard.vue'),
        meta: {
          title: "Dashboard",
          authRequired: true,
        }
      },
      {
        path: '/orders',
        name: 'order',
        component: () => import('@views/orders/OrderList.vue'),
        meta: {
          title: "Orders List",
          authRequired: true,
        },
      },
      {
        path: '/orders/:orderId(\\d+)',
        name: 'order-view',
        component: () => import('@views/orders/OrderShow.vue'),
        meta: {
          title: "View order",
          authRequired: true,
        },
      },
      {
        path: '/orders/:orderId(\\d+)/deliveries/:deliveryId(\\d+)',
        name: 'delivery-show',
        component: () => import('@views/orders/DeliveryShow.vue'),
        meta: {
          title: "View delivery",
          authRequired: true,
        },
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@views/notifications/NotificationList.vue'),
        meta: {
          title: "Notifications",
          authRequired: true,
        }
      },
      {
        path: '/team',
        name: 'team',
        component: () => import('@views/team/Team.vue'),
        meta: {
          title: 'Teams',
          authRequired: true,
        },
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('@views/account/UserEdit.vue'),
        meta: {
          title: 'Account',
          authRequired: true,
        },
      }     
    ]
  },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
  {
    path: '/full',
    component: () => import('@/layouts/Full.vue'),
    children: [     
      {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/pages/Error404.vue'),
        meta: {
          title: "404 Error",
        }
      },
      {
        path: '/error-500',
        name: 'error-500',
        component: () => import('@/views/pages/Error500.vue'),
        meta: {
          title: '500 Error',
        }
      },
      {
        path: '/auth',
        name: 'auth',
        component: () => import('@/views/pages/Authenticate.vue'),
        meta:{
          title: "Auto Login"
        }
      },
    ]
  },
  // Redirect to 404 page, if no match found
  {
    path: "/:any(.*)",
    redirect: '/error-404'
  },
];