
import axios from "@/plugins/axios.js"

const actions = {
    fetchProjects({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get("/projects")
          .then((response) => {
            if(response.data.success){
              commit('SET_PROJECTS', response.data.data.projects)
            }
            resolve(response)
          }).catch((error) => { reject(error) })
      })
    },
    fetchProject({ commit }, itemId) {
      return new Promise((resolve, reject) => {
        axios.get(`/projects/${itemId}/show`)
          .then((response) => {
            if(response.data.success){
              commit('SET_PROJECT', response.data.data)
            }
            resolve(response)
          }).catch((error) => { reject(error) })
      })
    },
    fetchMessages({ commit }, itemId) {
      return new Promise((resolve, reject) => {
        axios.get(`/project-messages?project_id=${itemId}`)
          .then((response) => {
            if(response.data.success){
              commit('SET_MESSAGES', response.data.data)
            }
            resolve(response)
          }).catch((error) => { reject(error) })
      })
    },
}

const getters = {}

const mutations = {
  SET_PROJECTS(state, projects) {
    state.projects = projects
  },
  SET_PROJECT(state, project){
    state.project = project
  },
  UPDATE_PROJECT(state, project){
    Object.assign(state.project, project)
    if(state.projects.length){
      const dataIndex = state.projects.findIndex((o) => o.id == project.id);
      Object.assign(state.projects[dataIndex], project);
    }
  },


  /**************** THIS IS FOR MESSAGES********************/
  SET_MESSAGES(state, data){
    state.project.messages = [...data]
  },
  ADD_MESSAGE(state, item){
    state.project.messages.push(item)
  },
  UPDATE_MESSAGE(state, item){
    const dataIndex = state.project.messages.findIndex((a) => a.id == item.id);
    Object.assign(state.project.messages[dataIndex], item);
  },
  DELETE_MESSAGE(state, itemId){
    const itemIndex = state.project.messages.findIndex((item) => item.id == itemId)
    state.project.messages.splice(itemIndex, 1)
  },
}

const state = {
  projects: [],
  project: null,
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
