import { createStore } from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleLayout from "./modules/layout.js"
import moduleNotification from "./modules/notification.js"
import moduleOrder from './modules/order.js'
import moduleProject from './modules/project.js'

const store = createStore({
  state,
  actions,
  getters,
  mutations,
  modules: {
    layout: moduleLayout,
    notification: moduleNotification,
    orderList: moduleOrder,
    project: moduleProject,
  },
  strict: process.env.NODE_ENV !== 'production',
})

export default store

