<template>
  <div v-if="addons.length" class="">
    <h5 class="mb-0 text-primary">Extra Services</h5>
    <hr class="mt-1"/>
    <div class="row">
      <div class="col-md-10 col-lg-8">
        <ul class="list-unstyled">
          <li v-for="(addon, adIndex) in addons" :key="adIndex" class="p-2 mb-3 border-bottom p-md-3 d-sm-flex align-items-center justify-content-between bg-light">
            <div class="mb-2 mb-sm-0" >
              <h5 class="mb-0" >{{addon.name}}</h5>
              <p class="mb-0"> {{addon.description}}</p>
            </div>
            <h5 class="mb-0">{{ currencySymbol }}{{$filters.money_format(addon.charge)}}</h5>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    addons:{
      type:Array,
      default: () => []
    },
    currencySymbol:{
      default: "$"
    }
  }
}
</script>
