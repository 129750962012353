<template>
    <div class="card card-height-100">
        <router-link class="stretched-link" :to="`/orders/${order.id}`"></router-link>
        <div class="card-body p-3">
        <div class="d-flex flex-column h-100">
            <div class="d-flex">
            <div class="flex-grow-1">
                <h5 class="mb-3">{{ item.name || item.title }} </h5>
            </div>
            </div>
            <div class="d-flex">
                <div class="flex-grow-1">
                    <h6 class="mb-1 fs-15">
                        <span class="text-dark">No.: #{{ order.number }}</span>
                    </h6>
                    <p class="text-muted text-truncate-two-lines mb-0">
                        Status: <span v-html="statusBadge(order.status)"></span>
                    </p>
                </div>
            </div>
        </div>
        </div>
        <div class="card-footer bg-transparent border-top-dashed py-2">
            <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                <div class="text-muted">
                    <i class="ri-calendar-event-fill me-1 align-bottom"></i>
                    {{ $filters.date_time(order.created_at,1) }}
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        order:{
            type:Object,
            required: true
        },
    },
    computed:{
        item(){
            return this.order.product
        }
    }
}
</script>

<style>

</style>