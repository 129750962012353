<template>
  <div class="order-overview pb-4" >
    <is-loading v-if="isLoading"/>

    <div v-else class="row">
      <div class="col-12 mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link to="/orders">Orders</router-link></li>
            <li class="breadcrumb-item active" aria-current="page">View order</li>
          </ol>
        </nav>
      </div>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12">
            <div v-if="order.status == 'draft'" >
              <b-alert :show="true"  variant="warning">
                <h5 class="alert-heading"><i class="mdi mdi-check-all me-2"></i>Saved as draft!</h5>
                <p class="mb-0">
                  <span class="me-2">This order was saved as draft. Proceed to make payment so that this order can be processed</span>
                </p>
                <hr class="my-2">
                <div class="d-flex justify-content-end">
                  <a class="btn btn-outline-primary me-2" :href="absoluteUrl(`/orders/${order.id}/payment`)">Pay Now!</a>
                  <a class="btn btn-outline-danger" @click.prevent="deleteDraft()" href="#">Delete Draft!</a>
                </div>
              </b-alert>
            </div>
            <div v-else>
              <div class="row gy-2 mb-4">
                <div class="col-md-6 col-lg-4">
                  <div class="mb-2"> <span class="me-2">Order No: <strong>#{{order.number}}</strong></span> </div>
                  <div class="mb-2">Status: <strong class="text-uppercase me-2">{{order.status}}</strong>  </div>
                  <div class="">Total Price: <strong class="text-uppercase">{{order.currency_symbol}}{{$filters.money_format(order.amount)}}</strong> </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="mb-2">Item: <strong class="text-uppercase me-2">{{item.name}}</strong> </div>
                  <div class="mb-2">Date: <strong class="text-uppercase me-2">{{ $filters.date_time(order.created_at,1) }}</strong> </div>
                  <div ><a class="text-success" :href="absoluteUrl(`/${item.slug}`)">View this product</a> </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="mb-2">Delivery date: <strong class="text-uppercase me-2">{{ $filters.deliveryDate(order.created_at, order.delivery_time)}}</strong> </div>
                  <div class="mb-0">Duration: <strong class="text-uppercase me-2">{{calculateDeliveryTime(order.delivery_time)}}</strong> </div>
                </div>
              </div>
              <div class="mb-3 d-flex">
                <div class="soft-bread w-auto overflow-hidden border shadow-sm">
                  <span class="soft-bread-item border-right"
                    :class="{'bg-success text-white': order.stage == 'design'}"><span style="z-index: 2;">DESIGN</span></span>
                    <span class="soft-bread-item border-right"
                    :class="{'bg-success text-white': order.stage == 'finalist'}"><span style="z-index: 2;">FINALIST</span></span>
                  <span class="soft-bread-item"
                  :class="{'bg-success text-white': order.stage == 'finished'}"><span style="z-index: 2;">FILES</span></span>
                </div>
              </div>
              <div class="mb-3">
                <div class="font-18 d-flex">
                  <i class="fa fa-clock me-2"></i>
                  <span class="font-16"> Maximum of <strong> 7 days</strong> is expected to choose finalist </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <b-tabs active-tab-class="active" 
              nav-class="bg-white shadow nav-tabs-custom nav-primary mb-3">
 
              <b-tab :active="activeTab === 1">
                <template v-slot:title>
                  <i class="mdi mdi-file-image me-1"></i>
                  <span class="nav-label">Designs</span>
                </template>
                <div class="card">
                  <div class="card-body px-lg-6">
                    <order-designs />
                  </div>
                </div>
              </b-tab>
              <b-tab :active="activeTab === 2">
                <template v-slot:title>
                  <i class="mdi mdi-book-outline me-1"></i>
                  <span class="nav-label">Brief</span>
                </template>
                <div class="card">
                  <div class="card-body px-lg-6">
                    <order-brief />
                  </div>
                </div>
              </b-tab>
              <b-tab :active="activeTab === 3" :lazy="activeTab != 2">
                <template v-slot:title>
                  <i class="mdi mdi-cloud-download me-1"></i>
                  <span class="nav-label">Files</span>
                </template>
                <div class="card">
                  <div class="card-body px-lg-6">
                    <order-downloads />
                  </div>
                </div>
              </b-tab>
              <b-tab :active="activeTab === 4">
                <template v-slot:title>
                  <i class="mdi mdi-book-open-variant me-1"></i>
                  <span class="nav-label">Notes</span> 
                </template>
                <div class="card">
                  <div class="card-body px-lg-6">
                    <order-notes />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div v-if="order.product" class="col-12 col-md-10 col-lg-9">
            <order-extra-service
              class="mb-3"
              v-if="['active','delivered','revision'].includes(order.status)"
              :order="order" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrderBrief from "@/components/order/OrderBrief.vue"
import OrderDownloads from "@/components/order/OrderDownloads.vue"
import OrderDesigns from "@/components/order/OrderDesigns.vue"
import OrderNotes from "@/components/order/OrderNotes.vue"
import OrderExtraService from '@/components/order/OrderExtraService.vue'
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "order-overview",
  components: {
    OrderBrief,
    OrderDownloads,
    OrderDesigns,
    OrderNotes,
    OrderExtraService,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
    }
  },
  watch: {
    '$route.params.orderId': function (id){
      if(id) this.fetchOrder()
    },
  },
  computed: {
    activeUser(){
      return this.$store.state.activeUser
    },
    order(){
      return this.$store.state.orderList.order
    },
    item(){
      return this.order.product
    },
    activeTab:{
      get() {
        return this.$route.query.tab ? +this.$route.query.tab : 0;
      },
      set(newValue) {
       
      }
    },
  },
  methods:{
    fetchOrder(){
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrder", this.$route.params.orderId)
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: "/error-404"})
        }
      })
    },
    deleteDraft(){
      this.confirmDelete({
        text: "You about to delete this draft",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/orders/${this.order.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path:'/orders'})
            }
          })
        }
      });
    },
  },
  created(){
    this.fetchOrder()
  },

}
</script>

<style lang="scss">
.order-overview .soft-bread{
  background: var(--bs-white)
}

.order-overview .soft-bread .soft-bread-item{
  display:inline-block;
  color: #000;
  padding: 0.2rem 0.8rem;
}

.order-overview .soft-bread .soft-bread-item.bg-success{
  color: white;
}
</style>
