
<template>
<div class="pb-5">
  <div class="row order-breakdown gy-3">
    <div class="col-6 col-lg-3">
      <div class="card">
        <div class="card-body position-relative">
          <h5 class="soep-title">Draft orders</h5>
          <h2 class="">{{statusCounter.draft}}</h2>
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-3">
      <div class="card">
        <div class="card-body position-relative">
          <h5 class="soep-title">Active orders</h5>
          <h2 class="">{{statusCounter.active}}</h2>
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-3">
      <div class="card">
        <div class="card-body position-relative">
          <h5 class="soep-title"> In Progress </h5>
          <h2 class="">{{statusCounter.progress}}</h2>
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-3">
      <div class="card">
        <div class="card-body position-relative">
          <h5 class="soep-title"> Completed </h5>
          <h2 class="">{{statusCounter.completed}}</h2>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!isLoading">
    <div class="row gy-2">
      <div class="col-12">
        <h5 class="mb-3">My Orders</h5>
        <div class="row g-4 mb-3">
          <div class="col-sm-auto">
            <div>
              <select v-model="defaultStatus" class="form-select" aria-label="Order Status">
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="progress">In progress</option>
                <option value="revision">Revision</option>
                <option value="completed">Completed</option>
              </select>
            </div>
          </div>
          <div class="col-sm">
            <div class="d-flex justify-content-sm-end gap-2">
              <div class="search-box">
                <input type="text" v-model="searchQuery" class="form-control" placeholder="Search..." />
                <i class="ri-search-line search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="orders.length" class="row gy-2">
      <div class="col-xxl-3 col-sm-6 project-card"
          v-for="(order, oIndex) of searchedOrders" :key="oIndex">
        <order-card :order="order"/>
      </div>
    </div>
    <div v-else class="fs-6 py-4 text-muted text-center">You don't have any order in your account.</div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>

import OrderCard from "@/components/cards/OrderCard.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  components:{
    OrderCard,
    IsLoading
  },
  data(){
    return {
      isLoading: true,
      defaultStatus: "all",
      searchQuery: "",
      statusCounter:{
        total:0,
        draft: 0,
        active: 0,
        progress: 0,
        completed :0
      }
    }
  },
  computed:{
    orders(){
      return this.defaultStatus == "all"
        ? this.$store.state.orderList.orders
        : this.$store.state.orderList.orders.filter(ord => ord.status == this.defaultStatus )
    },
    searchedOrders(){
      return this.orders.filter(order => order.number ? order.number.toLowerCase().includes(this.searchQuery.toLowerCase()) : false )
    },
  },
  created(){
    this.$store.dispatch("orderList/fetchOrders")
    .then((response) => { 
      this.statusCounter = response.data.data.counter
      this.isLoading = false; 
    });
  },
}

</script>

<style lang="scss">
</style>
