<template>
    <div>
    <div class="row dashboard py-4">
        <div class="col-12">
            <div class="row gy-3">
                <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
                    <user-email-verification />                   
                </div>
                <div class="col-12">
                    <div class="card">
                        <div class="bg-holder bg-card" style="background-image:url(assets/img/icons/spot-illustrations/corner-1.png);"></div>
                        <!--/.bg-holder-->
                        <div class="card-body position-relative">
                            <h5 class="text-primary">Checkout special offer!</h5>
                            <p class="mb-0">Time is running out ⚡ your handcrafted custom logo design @ 50% OFF | limited spots.</p>
                            <a class="btn btn-primary mt-3 me-3" :href="absoluteUrl('/logo-design')" target="_blank" >
                                Logo design
                                <i class="mdi mdi-chevron-right"></i>
                            </a>
                            <a class="btn btn-outline-primary mt-3" :href="absoluteUrl('/services')" target="_blank">
                                All Services
                                <i class="mdi mdi-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <order-list />
</div>
</template>
<script>

import UserEmailVerification from "@/components/UserEmailVerification.vue"
import OrderList from '@/views/orders/OrderList.vue'

export default {
    name: 'dashboard',
    components:{
        UserEmailVerification,
        OrderList,
    },
    data(){
        return {
            result:{
                orders: 0,
                projects: 0,
            }
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.activeUser
        },
    },
    created(){
        this.$http.get('/analytics')
        .then(response => {
          if(response.data.success){
            this.result = response.data.data
          }
      })
    }
}
</script>

<style scoped>
.user-photo{
    width:9rem;
}
@media(max-width:567px){
    .user-photo{
        width:4rem;
    }
    .order-breakdown .soep-title{
        font-size: 13px;
    }
}
.dashboard .table.profile-table td, .dashboard .table.profile-table th{
    padding:0.1rem 0.2rem;
}


</style>
