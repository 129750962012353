<template>
  <div class="order-brief">
     <package-features :item="order.package"
     :attributes="cart.attributes" class="mb-4" />

    <addons :currencySymbol="order.currency_symbol" :addons="cart.addons" class="mb-4" />
    
    <requirement-list :requirements="cart.requirements" />
  </div>
</template>

<script>

import Addons from "./Addons.vue"
import PackageFeatures from "./PackageFeatures.vue"
import RequirementList from "./RequirementList.vue"

export default {
  components:{
    Addons,
    PackageFeatures,
    RequirementList
  },
  computed:{
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
  },
}

</script>

