<template>
  <div class="order-designs">
    <b-alert :show="!isDelivered" variant="warning">
      <p class="mb-0">
        <i class="mdi mdi-block-helper mr-2"></i> <span>Your delivery files will show here.</span>
      </p>
    </b-alert>
    <div v-if="order.stage == 'finished' && winner" class="mb-3">
      <div class="border-bottom pb-1 mb-3">
        <h5 class="text-muted font-weight-bold text-success mb-0">Winner</h5>
        <p class="mb-0"> <strong>Congrats for choosing a winner!</strong> Your order process is complete.</p>
      </div>
      <div class="row g-3">
        <div class="col-6 col-sm-6 col-lg-3" >
          <div @click="showDelivery(winner.id)">
            <delivery-card :d-index="1"  :delivery="winner" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="finalists.length" class="mb-3">
      <div class="border-bottom pb-1 mb-3">
        <h5 class="text-muted font-weight-bold text-success mb-0">Finalists</h5>
        <p v-if="!winner" class="mb-0"> <strong>You haven't selected a winner!</strong> Proceed to select a winner by choosing a spot below.</p>
      </div>
      <div class="row g-3">
        <div v-for="(fDelivery, fdIndex) in finalists"  :key="fdIndex" class="col-6 col-sm-6 col-lg-3" >
          <div @click="showDelivery(fDelivery.id)">
            <delivery-card :d-index="fdIndex+1"  :delivery="fDelivery" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="progress.length" class="mb-3">
      <h5 class="border-bottom font-weight-bold text-success pb-1 mb-3 text-muted">In Progress</h5>
      <div class="row g-3">
        <div v-for="(pDelivery, pdIndex) in progress" :key="pdIndex" class="col-6 col-sm-6 col-lg-3" >
          <div @click="showDelivery(pDelivery.id)">
            <delivery-card :d-index="pdIndex+1"  :delivery="pDelivery" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="eliminated.length">
      <div class="border-bottom pb-1 mb-3">
        <h5 class="text-muted font-weight-bold text-success mb-0">Eliminated</h5>
      </div>
      <div class="row g-3">
        <div v-for="(eDelivery, fdIndex) in eliminated"  :key="fdIndex" class="col-6 col-sm-6 col-lg-3" >
          <div @click="showDelivery(eDelivery.id)">
            <delivery-card :d-index="fdIndex+1"  :delivery="eDelivery" />
          </div>
        </div>
      </div>
    </div>
    <b-modal size="sm" title="Pay now to have access"
        body-bg-variant="light" body-class="p-4"
         scrollable hide-footer v-model="payModalShow">
        <div>
            <h4>Amount Due: ${{order.amount}}</h4>
            <p>We have made it available for you to start this project and pay after delivery.</p>
            <p><span class="text-success font-weight-bold">Your delivery is ready.</span> Pay now, view the delivery and let the designer know your thoughts.</p>
            <a :href="absoluteUrl(`/orders/${order.id}/payment`)" class="btn btn-primary btn-block"> Pay Now </a>
        </div>
    </b-modal>
  </div>
</template>
<script>

import DeliveryCard from "./DeliveryCard.vue"

export default {
  components: {
    DeliveryCard,
  },
  data() {
    return {
      payModalShow: false,
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    deliveries(){
      return this.order.deliveries;
    },
    finalists(){
      return this.deliveries.filter(d => d.status == 'FINALIST')
    },
    progress(){
      return this.deliveries.filter(d => d.status == "PROGRESS")
    },
    winner(){
      return this.deliveries.find(d => d.status == 'WINNER') 
    },
    eliminated(){
      return this.deliveries.filter(d => d.status == "ELIMINATED")
    },
    isDelivered(){
      return this.order.deliveries ? this.order.deliveries.length : false;
    },
  },
  methods:{
    showDelivery(itemId){
      if(this.order.status == "postpay"){this.payModalShow = true; return}
      this.$router.push({path: `/orders/${this.order.id}/deliveries/${itemId}`})
    }
  },
  mounted(){
    if(parseInt(this.$route.query.target)){
      this.showDelivery(this.$route.query.target)
    }
  }
}
</script>
